import { CommonModule } from "@angular/common";
import { Component, Input, computed, effect, signal, inject } from "@angular/core";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { RestoService } from "@services/resto.service";

export type MenuItem = {
  icon: string;
  label: string;
  route: string;
};

@Component({
    selector: "app-custom-sidenav",
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        RouterLink,
        RouterLinkActive,
    ],
    template: `
    <div class="sidenav-header">
      <img
        [width]="profilePicSize()"
        [height]="profilePicSize()"
        [src]="imgResto"
        alt="resto_logo"
      />
      <div class="header-text" [class.hide-header-text]="sideNavCollapsed()">
        <h2>{{ nomResto }}</h2>
        <p>{{ nomStaff }}</p>
      </div>
    </div>

    <mat-nav-list>
      @for (item of menuItems(); track item) {
      <a
        mat-list-item
        class="menu-item"
        [routerLink]="item.route"
        routerLinkActive="selected-menu-item"
        #rla="routerLinkActive"
        [activated]="rla.isActive"
      >
        <mat-icon
          [fontSet]="
            rla.isActive ? 'material-icons' : 'material-icons-outlined'
          "
          matListItemIcon
          >{{ item.icon }}</mat-icon
        >
        @if (!sideNavCollapsed()) {
        <span matListItemTitle>{{ item.label }}</span>
        }
      </a>
      }

      <a mat-list-item class="menu-item" (click)="logOut()">
        <mat-icon matListItemIcon>logout</mat-icon>
         @if (!sideNavCollapsed()) { <span matListItemTitle> Déconnexion </span>   }
    </a>
    </mat-nav-list>
  `,
    styles: [
        `
      :host * {
        transition: all 500ms ease-in-out;
      }

      .mat-mdc-nav-list .mat-mdc-list-item {
      border-radius : 0px !important;
      }

      .sidenav-header {
        padding-top: 24px;
        text-align: center;

        > img {
          border-radius: 100%;
          object-fit: cover;
          margin-bottom: 10px;
        }

        .header-text {
          height: 3rem;

          > h2 {
            margin: 0;
            font-size: 1rem;
            line-height: 1.5rem;
            font-family: 'Playfair Display', sans-serif;
          }
        }
      }

      .hide-header-text {
        opacity: 0;
        height: 0px !important;
      }

      .menu-item {
        border-left: 5px solid;
        border-left-color: rgba(0, 0, 0, 0);
      }

      .selected-menu-item {
        border-left-color: #d30050;
        background: rgba(0, 0, 0, 0.05);
      }

      .logout {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-left: 18px;
        font-size: 18px;
      }

      .logout:hover {
        color: #d30050;
      }
    `,
    ]
})
export class CustomerSidenavComponent {
  private authService = inject(AuthService);
  private restoService = inject(RestoService);

  sideNavCollapsed = signal(false);
  nomResto: string;
  imgResto: string;
  nomStaff: string;
  @Input() set collapsed(val: boolean) {
    this.sideNavCollapsed.set(val);
  }

  menuItems = signal<MenuItem[]>([
    {
      icon: "dashboard",
      label: "Tableau de bord",
      route: "tableau-de-bord",
    },
    {
      icon: "storefront",
      label: "Mes informations",
      route: "infos",
    },
    {
      icon: "groups",
      label: "Mon Personnel",
      route: "staff",
    },
    {
      icon: "settings",
      label: "Paramètres",
      route: "parametres",
    },
    {
      icon: "redeem",
      label: "Offres",
      route: "offres",
    },
    {
      icon: "account_balance",
      label: "Règlement",
      route: "reglement",
    },
    {
      icon: "support_agent",
      label: "Support",
      route: "support",
    },
  ]);

  profilePicSize = computed(() => (this.sideNavCollapsed() ? "32" : "100"));

  isLog: boolean = false;

  constructor() {
      effect(() => {
        this.nomResto = this.restoService.nomResto();
        this.imgResto = this.restoService.imgResto();
        this.nomStaff = this.restoService.nomStaff();
      } )
    }

  logOut() {
    this.authService.logOutResto().then(() => {});
  }
}
