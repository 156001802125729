/// <reference types="@angular/localize" />

import { enableProdMode, importProvidersFrom } from "@angular/core";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { environment } from "./environments/environment";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { provideServiceWorker } from '@angular/service-worker';
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  provideFirestore,
} from "@angular/fire/firestore";
import { getStorage, provideStorage } from "@angular/fire/storage";
import { getAuth, provideAuth } from "@angular/fire/auth";
import {
  RouterModule,
  provideRouter,
  withInMemoryScrolling,
  withViewTransitions,
} from "@angular/router";
import { appRoutes } from "./app/app-routing.module";
import { getFunctions, provideFunctions } from "@angular/fire/functions";
import { AppComponent } from "./app/app.component";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>((resolve) => {
  resolvePersistenceEnabled = resolve;
});

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      appRoutes,
      withViewTransitions(),
      withInMemoryScrolling({ scrollPositionRestoration: "enabled" })
    ),
    importProvidersFrom([
      BrowserModule,
      BrowserAnimationsModule,
      QuicklinkModule,
      CommonModule,
      RouterModule.forRoot(appRoutes, {
        preloadingStrategy: QuicklinkStrategy,
        useHash: false,
        scrollPositionRestoration: "top",
      }),
    ]),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => {
      initializeFirestore(initializeApp(environment.firebaseConfig), {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        }),
      });
      const firestore = getFirestore();
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions(
        initializeApp(environment.firebaseConfig),
        "europe-west1"
      );
      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage(
        initializeApp(environment.firebaseConfig),
        "gs://tablesolutions-174b4.appspot.com"
      );
      return storage;
    }),
    provideAuth(() => getAuth()),
    provideServiceWorker("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerWhenStable:30000",
    }), provideAnimationsAsync(),
  ],
});
