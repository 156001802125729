// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
      apiKey: 'AIzaSyBnLQcaO4Tk9GvFMMv30cOx1zbwnQAZzFc',
      authDomain: 'tablesolutions-174b4.firebaseapp.com',
      databaseURL: 'https://tablesolutions-174b4.firebaseio.com',
      projectId: 'tablesolutions-174b4',
      storageBucket: 'tablesolutions-174b4.appspot.com',
      messagingSenderId: '958579211150',
      appId: '1:958579211150:web:b5d2488d503c1aa02682df',
      measurementId: 'G-1HLX7NB449'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
