import {
  Component,
  HostBinding,
  OnInit,
  computed,
  signal,
  inject,
} from "@angular/core";
import { RouterLink, RouterOutlet } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CustomerSidenavComponent } from "@services/shared/custom-sidenav/custom-sidenav.component";
import { Auth } from "@angular/fire/auth";
import { Staff } from "@interfaces/staff";
import { Resto } from "@interfaces/resto";
import { RestoService } from "@services/resto.service";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { OverlayContainer } from "@angular/cdk/overlay";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "app-root",
  template: `
    @if (!isMobile) { @if(islog) {
    <mat-toolbar class="my-primary">
      <button mat-icon-button (click)="collapsed.set(!collapsed())">
        <mat-icon>menu</mat-icon>
      </button>

      <h1
        routerLink="/tableau-de-bord"
        style="font-family: 'Ms Madi', cursive; font-size: 40px; cursor: pointer"
      >
        Back'<span
          style="font-family: 'Ms Madi', cursive;color: #D30050; font-size: 40px"
          >Office</span
        >
      </h1>

      <span style="flex: 1 1 auto;"></span>

      <button mat-icon-button (click)="switchMode()">
        <mat-icon>{{
          this.switchTheme.value ? "light_mode" : "dark_mode"
        }}</mat-icon>
      </button>
    </mat-toolbar>
    }
    <mat-sidenav-container>
      @if(islog && !isNew) {
      <mat-sidenav opened mode="side" [style.width]="sidenavWidth()">
        <app-custom-sidenav [collapsed]="collapsed()" />
      </mat-sidenav>
      }
      <mat-sidenav-content class="content" [style.margin-left]="sidenavWidth()">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
    } @else {
    <router-outlet></router-outlet>
    }
  `,
  styles: [
    `
      mat-toolbar {
        position: relative;
        z-index: 5;
        box-shadow: rgba(0, 0, 0, 0.15) 0 3px 3px 0;
      }

      mat-sidenav-container {
        height: calc(100vh - 64px);
      }

      mat-sidenav,
      mat-sidenav-content {
        transition: all 500ms ease-in-out;
      }

      .content {
        padding: 24px;
      }
    `,
  ],
  imports: [
    RouterOutlet,
    RouterLink,
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    CustomerSidenavComponent,
  ],
})
export class AppComponent implements OnInit {
  private restoService = inject(RestoService);
  private auth = inject(Auth);
  private overlay = inject(OverlayContainer);

  title = "Detec'Table";

  switchTheme = new FormControl(false);
  @HostBinding("class") className = "theme-light";
  darkClass = "theme-dark";
  lightClass = "theme-light";
  isDarkMode: boolean;

  collapsed = signal(false);
  sidenavWidth = computed(() => (this.collapsed() ? "65px" : "15%"));

  islog: boolean;
  isAdmin: any;
  isGestionnaire: any;
  isMarketing: any;
  isNew: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  userMail: string;
  userUID: string;
  restoUID: string;
  staff: Staff[] = [];
  restos: Resto[];

  constructor() {
    const breakpointObserver = inject(BreakpointObserver);

    breakpointObserver.observe(Breakpoints.XSmall).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
        this.isDesktopDevice = false;
      }
    });
    breakpointObserver
      .observe([
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = false;
          this.isDesktopDevice = true;
        }
      });
  }

  ngOnInit(): void {
    this.switchTheme.valueChanges.subscribe((currentMode) => {
      this.className = currentMode ? this.darkClass : this.lightClass;
      if (currentMode) {
        this.overlay.getContainerElement().classList.add(this.darkClass);
        localStorage.setItem("theme", "Dark");
      } else {
        this.overlay.getContainerElement().classList.remove(this.darkClass);
        localStorage.setItem("theme", "Light");
      }
    });

    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.islog = true;
        this.userMail = user.email;
        this.userUID = user.uid;
        user.getIdTokenResult().then((token) => {
          this.isAdmin = token.claims.Administrateur;
          this.isGestionnaire = token.claims.Gestionnaire;
          this.isMarketing = token.claims.Marketing;
        });
        if (this.userMail != "sebastien.ollivier@me.com") {
          this.restoService.getIdResto(this.userUID).subscribe((staffData) => {
            this.staff = staffData;

            this.restoUID = this.staff[0].restoUID;
            this.restoService
              .getOneResto(this.restoUID)
              .subscribe((dataUser) => {
                this.restos = dataUser;
                this.isNew = this.restos[0].isNew;
              });
          });
        } else {
          this.islog = false;
          this.isNew = false;
          this.isAdmin = false;
        }
      } else {
        this.islog = false;
        this.isNew = false;
        this.isAdmin = false;
      }
    });
  }

  switchMode() {
    const value = this.switchTheme.value;

    this.switchTheme.valueChanges.subscribe((currentMode) => {
      this.className = currentMode ? this.darkClass : this.lightClass;

      if (currentMode) {
        this.overlay.getContainerElement().classList.add(this.darkClass);
        document.documentElement.setAttribute("data-bs-theme", "dark");
      } else {
        this.overlay.getContainerElement().classList.remove(this.darkClass);
        document.documentElement.setAttribute("data-bs-theme", "light");
      }
    });

    if (value) {
      localStorage.setItem("theme", "Light");
    } else {
      localStorage.setItem("theme", "Dark");
    }
    this.switchTheme.setValue(!value);
  }
}
