import { Routes } from "@angular/router";
import { AuthGuard } from "./modules/shared/guard/auth.guard";

export const appRoutes: Routes = [
  // Login

  {
    path: "login",
    loadComponent: () =>
      import("./modules/restaurant/auth/login/login.component").then(
        (mod) => mod.LoginComponent
      ),
  },

  {
    path: "reset",
    loadComponent: () =>
      import("./modules/restaurant/auth/reset/reset.component").then(
        (mod) => mod.ResetComponent
      ),
  },

  {
    path: "tableau-de-bord",
    loadComponent: () =>
      import(
        "./modules/restaurant/tableau-de-bord/tableau-de-bord.component"
      ).then((mod) => mod.TableauDeBordComponent),
    canActivate: [AuthGuard],
  },

  {
    path: "tableau-de-bord/resume",
    loadComponent: () =>
      import("./modules/shared/resume/resume.component").then(
        (mod) => mod.ResumeComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "planning",
    loadComponent: () =>
      import("./modules/restaurant/planning/planning.component").then(
        (mod) => mod.PlanningComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "infos",
    loadComponent: () =>
      import("./modules/restaurant/edit-resto/edit-resto.component").then(
        (mod) => mod.EditRestoComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "staff",
    loadComponent: () =>
      import("./modules/restaurant/staff/staff.component").then(
        (mod) => mod.StaffComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "offres",
    loadComponent: () =>
      import("./modules/restaurant/action/action.component").then(
        (mod) => mod.ActionComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "parametres",
    loadComponent: () =>
      import("./modules/restaurant/settings/settings.component").then(
        (mod) => mod.SettingsComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "support",
    loadComponent: () =>
      import("./modules/restaurant/support/support.component").then(
        (mod) => mod.SupportComponent
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "reglement",
    loadComponent: () =>
      import("./modules/restaurant/bank-setting/bank-setting.component").then(
        (mod) => mod.BankSettingComponent
      ),
    canActivate: [AuthGuard],
  },

  // New

  {
    path: "new/etape1",
    loadComponent: () =>
      import("./modules/shared/New/etape1/etape1.component").then(
        (mod) => mod.Etape1Component
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "new/etape2",
    loadComponent: () =>
      import("./modules/shared/New/etape2/etape2.component").then(
        (mod) => mod.Etape2Component
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "new/etape3",
    loadComponent: () =>
      import("./modules/shared/New/etape3/etape3.component").then(
        (mod) => mod.Etape3Component
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "new/etape4",
    loadComponent: () =>
      import("./modules/shared/New/etape4/etape4.component").then(
        (mod) => mod.Etape4Component
      ),
    canActivate: [AuthGuard],
  },

  { path: "", redirectTo: "/tableau-de-bord", pathMatch: "full" },
  { path: "**", redirectTo: "/tableau-de-bord", pathMatch: "full" },
];

export class AppRoutingModule {}
